* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #f4f6f8;
    height: 100%;
}

a {
    text-decoration: none;
}

#root {
    height: 100%;
}

.MuiAppBar-colorPrimary {
    color: #FFFFFF;
    background-color: #016672!important;
}

.MuiAppBar-colorPrimary img {
    width: 150px;
}

.MuiButton-containedPrimary {
    color: #FFFFFF;
    background-color: #016672!important;
}

.ajutscont {
    padding-left: 22px;
    padding-right: 22px;
}

img.ajusticiar {
    width: 150px;
}

.MuiTypography-h4 {
    color: #ffffff!important;
}

.MuiTypography-body2 {
    color: #ffffff!important;
}

.makeStyles-button-16 {
    color: #ffffff!important;
}

.MuiButton-label {
    color: #ffffff;
}

.MuiButton-label .ajustes-icon {
    height: auto;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiButton-label .ajustes-icon .MuiSvgIcon-root {
    color: #ffffff!important;
}

.cargando {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.cargando img {
    width: 150px;
}

p {
    color: #000;
    font-size: 20px;
    font-weight: bold;
}

.sidebarfinal {
    background-color: #222d32!important;
}

h1 {
    margin-top: 80px;
    font-weight: 100;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
    background: #0e101c;
    max-width: 400px;
    margin: 0 auto;
}

button[type="button"] {
    background: black;
    border: 1px solid white;
}

p {
    color: #016672;
    text-align: center;
}

input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d6d3d3;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d6d3d3;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

.materialUIInput {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    background-color: white;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
}

.IceCreamType {
    color: black;
}

label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 0px;
    margin-top: 0px;
    color: #000;
    font-size: 14px;
    font-weight: bold;
}

input[type="submit"] {
    background: #016672;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
    width: 200px;
}

.tableajust {
    background-color: #ffffff;
    border-radius: 10px;
}

.tableajust h1 {
    margin-top: 0px;
}

.titulofinal {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #000;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    text-align: center;
}

.buttonfinal {
    display: block;
    appearance: none;
    border-radius: 4px;
    width: 100%;
}

input[type="submit"]:hover {
    background: #bf1650;
}

input[type="button"]:active,
input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
}

input:disabled {
    opacity: 0.4;
}

input[type="button"]:hover {
    transition: 0.3s all;
}

input[type="button"],
input[type="submit"] {
    -webkit-appearance: none;
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.counter {
    font-weight: 400;
    background: white;
    color: black;
    padding: 10px 15px;
    border-radius: 4px;
    position: fixed;
    top: 20px;
    right: 20px;
}

form.formfinal {
    background-color: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
}